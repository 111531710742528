import axios from "axios";

export const baseURL = "https://erblan.vercel.app/"


export const createUser = async (data) => {
    try {
        const res = await axios.post(`${baseURL}account/register/`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return res;
      } catch (error) {
        console.error("Error during registration:", error);
        return error;
      }
}

export const loginUser = async (data) => {
  try {
    const res = await axios.post(`${baseURL}account/login/`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
     return res;
    } catch (error) {
      console.error("Error during login:", error);
      return null;
    }
}

export const resetPassword = async (data) => {
  try {
    const res = await axios.post(`${baseURL}account/password/reset/`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
     return res;
    } catch (error) {
      console.error("Error during reset:", error);
      return error;
    }
}


export const resetPasswordConfirm = async (data) => {  //token and password
  try {
    const res = await axios.post(`${baseURL}account/passwordreset/confirm/`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
     return res;
    } catch (error) {
      console.error("Error during reset:", error);
      return null;
    }
}


export const listCategory = async () => {
  try {
    const res = await axios.get(`${baseURL}store/categories/`)
     return res.data
  } catch (error) {
    return null
  }
}

export const listParticularCategory = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}store/category/${slug}/`)
     return res.data
  } catch (error) {
    return null
  }
}





